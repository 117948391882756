// @flow

export const COLOR_WITH_RGB: string[][] = [
  ['薰衣草紫红', '#fff0f5', '255,245,245'],
  ['淡藕合', '#f0dfee', '240,238,238'],
  ['甘石粉', '#ffe8f3', '255,243,243'],
  ['浅粉红', '#ffd9e6', '255,230,230'],
  ['浅血牙', '#ffd7ea', '255,234,234'],
  ['粉凤仙', '#fccce6', '252,230,230'],
  ['浅珍珠红', '#ffb3e6', '255,230,230'],
  ['淡粉', '#ffb3cc', '255,204,204'],
  ['火鹤红', '#e68ab8', '230,184,184'],
  ['豆棕', '#502e3e', '80,62,62'],
  ['茄皮紫', '#3a212f', '58,47,47'],
  ['蒲萄灰', '#4e2c3f', '78,63,63'],
  ['荷花', '#eb7faf', '235,175,175'],
  ['灰紫红', '#db7093', '219,147,147'],
  ['浅珊瑚红', '#ff80bf', '255,191,191'],
  ['暗粉', '#ff77bb', '255,187,187'],
  ['陈玫红', '#b85798', '184,152,152'],
  ['肉红', '#d26091', '210,145,145'],
  ['尖晶石红', '#ff73b3', '255,179,179'],
  ['银红', '#c85b92', '200,146,146'],
  ['暖粉红', '#ff69b4', '255,180,180'],
  ['浅玫瑰红', '#ff66cc', '255,204,204'],
  ['紫扇贝色', '#923a60', '146,96,96'],
  ['长春花色', '#ff47d1', '255,209,209'],
  ['山茶红', '#e63995', '230,149,149'],
  ['中青紫红', '#c71585', '199,133,133'],
  ['深粉红', '#ff1493', '255,147,147'],
  ['洋玫瑰红', '#ff0da6', '255,166,166'],
  ['玫瑰红', '#ff007f', '255,127,127'],
  ['品红', '#f400a1', '244,161,161'],
  ['胭脂红', '#e6005c', '230,92,92'],
  ['艶粉', '#e6005c', '230,92,92'],
  ['红宝石色', '#cc0080', '204,128,128'],
  ['枢机红', '#990036', '153,54,54'],
  ['毅红', '#8a002e', '138,46,46'],
  ['牵牛紫', '#800040', '128,64,64'],
  ['紫粉', '#80002b', '128,43,43'],
  ['鶏冠花红', '#660033', '102,51,51'],
  ['枣红', '#640021', '100,33,33'],
  ['紫绛色', '#51001b', '81,27,27'],
  ['玫瑰灰', '#4d0026', '77,38,38'],
  ['勃艮第酒红', '#470024', '71,36,36'],
  ['黒紫', '#3e0014', '62,20,20'],
  ['雪紫', '#33001a', '51,26,26'],
  ['深红', '#33001a', '51,26,26'],
  ['雪色', '#fffafa', '255,250,250'],
  ['浅肉粉', '#f5eeeb', '245,235,235'],
  ['粉棕', '#ebdad3', '235,211,211'],
  ['雾玫瑰色', '#ffe4e1', '255,225,225'],
  ['米红', '#fddfdf', '253,223,223'],
  ['沙棕', '#e6c3c3', '230,195,195'],
  ['肉粉', '#ffd7d7', '255,215,215'],
  ['奶棕', '#deb6b6', '222,182,182'],
  ['玫瑰褐', '#bc8f8f', '188,143,143'],
  ['红灰莲', '#ab8282', '171,130,130'],
  ['粉红', '#ffc0cb', '255,203,203'],
  ['亮粉红', '#ffb6c1', '255,193,193'],
  ['壳黄红', '#ffb3bf', '255,191,191'],
  ['浅粉', '#ffb3b3', '255,179,179'],
  ['十様锦', '#ffb3b3', '255,179,179'],
  ['深烟', '#311f1e', '49,30,30'],
  ['凤仙粉', '#ff9393', '255,147,147'],
  ['亮珊瑚色', '#f08080', '240,128,128'],
  ['暗鲑红', '#e9967a', '233,122,122'],
  ['绛紫', '#492525', '73,37,37'],
  ['浅鲑红', '#ff8099', '255,153,153'],
  ['紫铜色', '#6b3636', '107,54,54'],
  ['珈琲色', '#623131', '98,49,49'],
  ['烟栗色', '#221311', '34,17,17'],
  ['亮鲑红', '#ffa07a', '255,122,122'],
  ['红莲灰', '#c76079', '199,121,121'],
  ['鲑红', '#fa8072', '250,114,114'],
  ['印度红', '#cd5c5c', '205,92,92'],
  ['银朱', '#c85554', '200,84,84'],
  ['赦红', '#d1634b', '209,75,75'],
  ['二硃', '#c24545', '194,69,69'],
  ['芯木色', '#c14444', '193,68,68'],
  ['銹红', '#4d1919', '77,25,25'],
  ['珊瑚红', '#ff7f50', '255,80,80'],
  ['辰沙', '#ce3b3b', '206,59,59'],
  ['红扇贝色', '#ce3b3b', '206,59,59'],
  ['蕃茄红', '#ff6347', '255,71,71'],
  ['柿子橙', '#ff4d40', '255,64,64'],
  ['妃红', '#b8542e', '184,46,46'],
  ['甎红', '#ab2b2b', '171,43,43'],
  ['褐色', '#a52a2a', '165,42,42'],
  ['硃砂', '#972626', '151,38,38'],
  ['土红', '#bc2e2e', '188,46,46'],
  ['樱桃红', '#de3163', '222,99,99'],
  ['砖红色', '#b22222', '178,34,34'],
  ['茜红', '#e32636', '227,54,54'],
  ['银星海棠', '#f52443', '245,67,67'],
  ['橙色', '#fe621f', '254,31,31'],
  ['桔红', '#ff441a', '255,26,26'],
  ['柿子色', '#ff531a', '255,26,26'],
  ['绯红', '#dc143c', '220,60,60'],
  ['月季红', '#e10b2b', '225,43,43'],
  ['鸟罗松红', '#c80926', '200,38,38'],
  ['蕉红色', '#d70419', '215,25,25'],
  ['艶红', '#bf0417', '191,23,23'],
  ['烟色', '#2e0a01', '46,1,1'],
  ['牡丹红', '#b80233', '184,51,51'],
  ['榴花红', '#b02502', '176,2,2'],
  ['枣紫', '#540101', '84,1,1'],
  ['红色', '#ff0000', '255,0,0'],
  ['腥红', '#ff2400', '255,0,0'],
  ['橙红', '#ff4500', '255,0,0'],
  ['朱红', '#ff4d00', '255,0,0'],
  ['鲜红', '#e60000', '230,0,0'],
  ['曙红', '#e60039', '230,57,57'],
  ['章丹', '#dd3700', '221,0,0'],
  ['茉莉花红', '#cc0033', '204,51,51'],
  ['猩红', '#cc0000', '204,0,0'],
  ['象牙红', '#b30000', '179,0,0'],
  ['血红', '#990000', '153,0,0'],
  ['深釉红', '#971b00', '151,0,0'],
  ['暗红', '#8b0000', '139,0,0'],
  ['栗色', '#800000', '128,0,0'],
  ['釉红', '#7b1800', '123,0,0'],
  ['臙脂', '#700000', '112,0,0'],
  ['花卉白', '#fffaf0', '255,240,240'],
  ['海贝色', '#fff5ee', '255,238,238'],
  ['浅棕灰', '#eee7dd', '238,221,221'],
  ['亚麻色', '#faf0e6', '250,230,230'],
  ['胡粉', '#fffae8', '255,232,232'],
  ['旧蕾丝色', '#fdf5e6', '253,230,230'],
  ['银白色', '#fffae6', '255,230,230'],
  ['铁灰色', '#625b57', '98,87,87'],
  ['蝋白', '#fef8de', '254,222,222'],
  ['玉米丝色', '#fff8dc', '255,220,220'],
  ['古董白', '#faebd7', '250,215,215'],
  ['红梍', '#3a3932', '58,50,50'],
  ['蕃木瓜色', '#ffefd5', '255,213,213'],
  ['芽灰', '#e3dbbf', '227,191,191'],
  ['米灰', '#d3cbaf', '211,175,175'],
  ['元青', '#24231e', '36,30,30'],
  ['果肉色', '#fee3d1', '254,209,209'],
  ['杏仁白', '#ffebcd', '255,205,205'],
  ['柠檬绸色', '#fffacd', '255,205,205'],
  ['乳白色', '#fef3c9', '254,201,201'],
  ['淡黄', '#fef8c9', '254,201,201'],
  ['真丝紬色', '#ffe3c8', '255,200,200'],
  ['肉黄', '#fce2c4', '252,196,196'],
  ['芦黄', '#ebd2b8', '235,184,184'],
  ['陶坯黄', '#ffe4c4', '255,196,196'],
  ['深银灰', '#c7bd9a', '199,154,154'],
  ['承徳梍', '#474336', '71,54,54'],
  ['粉扑桃色', '#ffdab9', '255,185,185'],
  ['小麦色', '#f5deb3', '245,179,179'],
  ['乳黄色', '#feefb6', '254,182,182'],
  ['小鸠黄', '#efe7ab', '239,171,171'],
  ['鹿皮鞋色', '#ffe4b5', '255,181,181'],
  ['桃色', '#ffe5b4', '255,180,180'],
  ['牙黄', '#fef4b4', '254,180,180'],
  ['富春纺色', '#fef4b4', '254,180,180'],
  ['乳灰', '#cfc592', '207,146,146'],
  ['灰米', '#c4be8c', '196,140,140'],
  ['庭院瓦灰色', '#706750', '112,80,80'],
  ['肉棕', '#debf9a', '222,154,154'],
  ['灰土色', '#ccb38c', '204,140,140'],
  ['那瓦霍白', '#ffdead', '255,173,173'],
  ['甘草黄', '#eddd9e', '237,158,158'],
  ['日晒色', '#d2b48c', '210,140,140'],
  ['纸棕', '#d2b38c', '210,140,140'],
  ['相思灰', '#4a4331', '74,49,49'],
  ['蝶粉', '#ffd0a2', '255,162,162'],
  ['乳棕', '#c9b481', '201,129,129'],
  ['燋茶色', '#3d3a27', '61,39,39'],
  ['浅驼色', '#d7af88', '215,136,136'],
  ['中驼', '#ad876b', '173,107,107'],
  ['玛瑙粉', '#ffbc9b', '255,155,155'],
  ['硬木色', '#deb887', '222,135,135'],
  ['土布色', '#fdd599', '253,153,153'],
  ['骆驼色', '#dcb183', '220,131,131'],
  ['亮卡其色', '#f0e68c', '240,140,140'],
  ['中棕灰', '#695f3d', '105,61,61'],
  ['淡棕茶', '#c0a86d', '192,109,109'],
  ['草灰色', '#3d3123', '61,35,35'],
  ['肉棕灰', '#cd9b69', '205,105,105'],
  ['珈琲棕', '#705438', '112,56,56'],
  ['深栗色', '#402f20', '64,32,32'],
  ['深褐色', '#251c12', '37,18,18'],
  ['黄棕色', '#b39255', '179,85,85'],
  ['杏黄', '#e69966', '230,102,102'],
  ['獣皮色', '#c69c57', '198,87,87'],
  ['驼色', '#a16b47', '161,71,71'],
  ['卡其黄', '#cda456', '205,86,86'],
  ['蜜橙', '#ffb366', '255,102,102'],
  ['茉莉黄', '#e6c35c', '230,92,92'],
  ['红孤色', '#c98150', '201,80,80'],
  ['沙褐', '#f4a460', '244,96,96'],
  ['蛤蜊粉', '#ffb061', '255,97,97'],
  ['丹东石', '#f9e459', '249,89,89'],
  ['南瓜黄', '#f4ad57', '244,87,87'],
  ['浅黄', '#f3aa58', '243,88,88'],
  ['鹿皮色', '#fad156', '250,86,86'],
  ['赦石色', '#d17547', '209,71,71'],
  ['秘鲁色', '#cd853f', '205,63,63'],
  ['向日黄', '#ffc34d', '255,77,77'],
  ['将校呢', '#363210', '54,16,16'],
  ['棕茶', '#c78738', '199,56,56'],
  ['铜色', '#b87333', '184,51,51'],
  ['赭黄', '#a0522d', '160,45,45'],
  ['桂皮色', '#c66f35', '198,53,53'],
  ['罗汉果色', '#502f16', '80,22,22'],
  ['醤色', '#39200f', '57,15,15'],
  ['雄黄', '#c66231', '198,49,49'],
  ['金黄', '#b85c2e', '184,46,46'],
  ['松皮色', '#a45128', '164,40,40'],
  ['棕红', '#934824', '147,36,36'],
  ['黄鸭色', '#874521', '135,33,33'],
  ['浅桔黄', '#ec9433', '236,51,51'],
  ['热带橙', '#ff8033', '255,51,51'],
  ['卡其色', '#996b1f', '153,31,31'],
  ['乌贼墨色', '#704214', '112,20,20'],
  ['赭色', '#cc7722', '204,34,34'],
  ['明黄', '#ffc428', '255,40,40'],
  ['丝爪花黄', '#fed927', '254,39,39'],
  ['大赤金', '#e6b422', '230,34,34'],
  ['金菊色', '#daa520', '218,32,32'],
  ['粉橙色', '#ff7b24', '255,36,36'],
  ['巧克力色', '#d2691e', '210,30,30'],
  ['鞍褐', '#8b4513', '139,19,19'],
  ['选金', '#e9c61f', '233,31,31'],
  ['库金', '#e0b712', '224,18,18'],
  ['玛瑙色', '#b1470e', '177,14,14'],
  ['黄金色', '#dab710', '218,16,16'],
  ['琉璃色', '#c9780c', '201,12,12'],
  ['暗金菊色', '#b8860b', '184,11,11'],
  ['坛香色', '#d57306', '213,6,6'],
  ['苍黄', '#c65306', '198,6,6'],
  ['艶黄', '#fc8705', '252,5,5'],
  ['雄精', '#d94f04', '217,4,4'],
  ['姜黄', '#806102', '128,2,2'],
  ['朱古力色', '#381801', '56,1,1'],
  ['蛋黄', '#e6a202', '230,2,2'],
  ['黄琉璃', '#e4b803', '228,3,3'],
  ['枝黄', '#cfb603', '207,3,3'],
  ['鹿皮棕', '#a67902', '166,2,2'],
  ['阳橙', '#ff7300', '255,0,0'],
  ['暗橙', '#ff8c00', '255,0,0'],
  ['万寿菊黄', '#ff9900', '255,0,0'],
  ['琥珀色', '#ffbf00', '255,0,0'],
  ['金色', '#ffd700', '255,0,0'],
  ['石黄', '#f9bb00', '249,0,0'],
  ['鹅黄', '#f8b500', '248,0,0'],
  ['橘色', '#f28500', '242,0,0'],
  ['桔黄', '#f08300', '240,0,0'],
  ['硃膘', '#eb6101', '235,1,1'],
  ['土黄', '#e88b00', '232,0,0'],
  ['铬黄', '#e6b800', '230,0,0'],
  ['金红', '#e65c00', '230,0,0'],
  ['虾黄', '#db6d00', '219,0,0'],
  ['燃橙', '#cc5500', '204,0,0'],
  ['椰褐', '#4d1f00', '77,0,0'],
  ['咖啡色', '#4d3900', '77,0,0'],
  ['灯草灰', '#131100', '19,0,0'],
  ['蛤粉', '#fdfff4', '253,244,244'],
  ['红灰', '#5f605b', '95,91,91'],
  ['象牙色', '#fffff0', '255,240,240'],
  ['貂水灰', '#8b8e86', '139,134,134'],
  ['蝋黄', '#edf9df', '237,223,223'],
  ['米黄色', '#f5f5dc', '245,220,220'],
  ['松緑色', '#ced5bd', '206,189,189'],
  ['黄灰', '#b6bea9', '182,169,169'],
  ['亮黄', '#ffffe0', '255,224,224'],
  ['春緑', '#e3efd1', '227,209,209'],
  ['承徳灰', '#4f4f44', '79,68,68'],
  ['油烟墨', '#272923', '39,35,35'],
  ['淡米色', '#ffffd7', '255,215,215'],
  ['亮金菊黄', '#fafad2', '250,210,210'],
  ['芦灰', '#c1d0ae', '193,174,174'],
  ['奶緑', '#cadcb6', '202,182,182'],
  ['奶油色', '#fffdd0', '255,208,208'],
  ['水黄', '#ddeec4', '221,196,196'],
  ['銹緑', '#d9e7ba', '217,186,186'],
  ['虾灰', '#dad6ab', '218,171,171'],
  ['法国梧桐皮色', '#949773', '148,115,115'],
  ['灰金菊色', '#eee8aa', '238,170,170'],
  ['中条虾緑', '#687452', '104,82,82'],
  ['茶緑', '#343724', '52,36,36'],
  ['牙緑', '#f4fea3', '244,163,163'],
  ['鉄緑', '#3f472c', '63,44,44'],
  ['香槟黄', '#ffff99', '255,153,153'],
  ['浅草緑', '#ccff99', '204,153,153'],
  ['枯緑', '#d3d37c', '211,124,124'],
  ['铜緑色', '#a6d279', '166,121,121'],
  ['嫩葱緑', '#93b469', '147,105,105'],
  ['暗卡其色', '#bdb76b', '189,107,107'],
  ['青豆色', '#98bc67', '152,103,103'],
  ['苦瓜緑', '#e1ff86', '225,134,134'],
  ['卡其緑', '#717234', '113,52,52'],
  ['暗橄榄绿', '#556b2f', '85,47,47'],
  ['杨桃色', '#eefd6a', '238,106,106'],
  ['嫩姜黄', '#fef263', '254,99,99'],
  ['芥末黄', '#cccc4d', '204,77,77'],
  ['黄緑', '#8cb33e', '140,62,62'],
  ['草绿', '#99e64d', '153,77,77'],
  ['月黄', '#ffff4d', '255,77,77'],
  ['槐黄', '#ffff4d', '255,77,77'],
  ['藤黄', '#faed4b', '250,75,75'],
  ['苔藓绿', '#697723', '105,35,35'],
  ['橄榄军服绿', '#6b8e23', '107,35,35'],
  ['黄绿', '#9acd32', '154,50,50'],
  ['含羞草黄', '#e6d933', '230,51,51'],
  ['绿黄', '#adff2f', '173,47,47'],
  ['嫩草緑', '#a3b61f', '163,31,31'],
  ['橄榄緑', '#49460c', '73,12,12'],
  ['柠檬黄', '#ffff24', '255,36,36'],
  ['草黄', '#f4ea20', '244,32,32'],
  ['深芽緑', '#739b06', '115,6,6'],
  ['军緑', '#3d6402', '61,2,2'],
  ['鲜黄', '#ffff00', '255,0,0'],
  ['黄色', '#ffff00', '255,0,0'],
  ['亮柠檬绿', '#ccff00', '204,0,0'],
  ['查特酒绿', '#7fff00', '127,0,0'],
  ['草坪绿', '#7cfc00', '124,0,0'],
  ['苹果绿', '#8ce600', '140,0,0'],
  ['橄榄色', '#808000', '128,0,0'],
  ['新芽色', '#7d7d00', '125,0,0'],
  ['蜜瓜绿', '#f0fff0', '240,240,240'],
  ['织锦灰', '#7b8c7c', '123,124,124'],
  ['竜泉青瓷釉色', '#c8e6c6', '200,198,198'],
  ['暗灰', '#465146', '70,70,70'],
  ['深灰', '#353e35', '53,53,53'],
  ['橄揽灰', '#75856d', '117,109,109'],
  ['冬灰色', '#63715b', '99,91,91'],
  ['苔灰', '#425140', '66,64,64'],
  ['暗海绿', '#8fbc8f', '143,143,143'],
  ['暗苔緑', '#405742', '64,66,66'],
  ['灰绿', '#98fb98', '152,152,152'],
  ['亮绿', '#90ee90', '144,144,144'],
  ['荷茎緑', '#a0d878', '160,120,120'],
  ['果緑', '#8ace57', '138,87,87'],
  ['葱緑', '#99f158', '153,88,88'],
  ['钴绿', '#66ff59', '102,89,89'],
  ['叶绿', '#73b839', '115,57,57'],
  ['嫩绿', '#99ff4d', '153,77,77'],
  ['常春藤绿', '#36bf36', '54,54,54'],
  ['柠檬绿', '#32cd32', '50,50,50'],
  ['森林绿', '#228b22', '34,34,34'],
  ['鹦鹉緑', '#156a1e', '21,30,30'],
  ['孔雀石绿', '#22c32e', '34,46,46'],
  ['双緑', '#043403', '4,3,3'],
  ['明绿', '#66ff00', '102,0,0'],
  ['鲜绿色', '#00ff00', '0,0,0'],
  ['绿色', '#008000', '0,0,0'],
  ['暗绿', '#006400', '0,0,0'],
  ['鉄灰', '#3e403f', '62,63,63'],
  ['薄荷奶油色', '#f5fffa', '245,250,250'],
  ['深瓦灰', '#717774', '113,116,116'],
  ['大理石色', '#c9d8cd', '201,205,205'],
  ['淡灰緑', '#c0d6cb', '192,203,203'],
  ['粗晶梍', '#323836', '50,54,54'],
  ['枝緑', '#dffff5', '223,245,245'],
  ['淡緑', '#dbfbe3', '219,227,227'],
  ['青虾色', '#6f847d', '111,125,125'],
  ['浅水緑', '#ccfbea', '204,234,234'],
  ['蟹青', '#6f9388', '111,136,136'],
  ['油緑', '#2b3c2f', '43,47,47'],
  ['三緑', '#b3ffbc', '179,188,188'],
  ['苍色', '#a6ffcc', '166,204,204'],
  ['墨緑', '#1e3124', '30,36,36'],
  ['老緑', '#2d5131', '45,49,49'],
  ['碧蓝色', '#7fffd4', '127,212,212'],
  ['青瓷绿', '#73e68c', '115,140,140'],
  ['中碧蓝色', '#66cdaa', '102,170,170'],
  ['玉緑', '#6ff9c1', '111,193,193'],
  ['夜緑色', '#285b41', '40,65,65'],
  ['冬緑', '#337d56', '51,86,86'],
  ['碧绿', '#50c878', '80,120,120'],
  ['緑琉璃色', '#296939', '41,57,57'],
  ['中海绿', '#3cb371', '60,113,113'],
  ['绿松石绿', '#4de680', '77,128,128'],
  ['冬瓜緑', '#349e69', '52,105,105'],
  ['海绿', '#2e8b57', '46,87,87'],
  ['铬绿', '#127436', '18,54,54'],
  ['薄荷绿', '#16982b', '22,43,43'],
  ['粗孔雀緑', '#028255', '2,85,85'],
  ['深緑宝石', '#02774f', '2,79,79'],
  ['巴黎緑', '#02b340', '2,64,64'],
  ['翡翠', '#015437', '1,55,55'],
  ['春绿', '#00ff80', '0,128,128'],
  ['中春绿色', '#00fa9a', '0,154,154'],
  ['孔雀绿', '#00a15c', '0,92,92'],
  ['正灰', '#bcc7c7', '188,199,199'],
  ['鸭蛋青', '#e6fffd', '230,253,253'],
  ['浅青色', '#e0f3f8', '224,248,248'],
  ['月白色', '#daf3ef', '218,239,239'],
  ['云灰', '#cbe2e4', '203,228,228'],
  ['亮青', '#e0ffff', '224,255,255'],
  ['蓝灰色', '#8ba39e', '139,158,158'],
  ['春蓝', '#98b4b3', '152,179,179'],
  ['石板色', '#4a595b', '74,91,91'],
  ['婴儿粉蓝', '#b0e0e6', '176,230,230'],
  ['水色', '#afdfe4', '175,228,228'],
  ['亮蓝', '#add8e6', '173,230,230'],
  ['灰绿松石色', '#afeeee', '175,238,238'],
  ['浅鲜緑', '#b6fee9', '182,233,233'],
  ['鸠灰', '#9ec8da', '158,218,218'],
  ['湖蓝灰', '#425c60', '66,96,96'],
  ['蟹蓝', '#597e82', '89,130,130'],
  ['灰蓝', '#477066', '71,102,102'],
  ['黛蓝', '#131e1e', '19,30,30'],
  ['果灰', '#7ecdb6', '126,182,182'],
  ['碧玉石', '#66a8a8', '102,168,168'],
  ['灰緑', '#5c968a', '92,138,138'],
  ['军服蓝', '#5f9ea0', '95,160,160'],
  ['暗岩灰', '#2f4f4f', '47,79,79'],
  ['浅松緑', '#79d2d2', '121,210,210'],
  ['天蓝', '#87ceeb', '135,235,235'],
  ['玉石蓝', '#427371', '66,113,113'],
  ['毛緑', '#28414a', '40,74,74'],
  ['水蓝', '#66ffe6', '102,230,230'],
  ['萨克斯蓝', '#4798b3', '71,179,179'],
  ['深松緑', '#42bfac', '66,172,172'],
  ['中绿松石色', '#48d1cc', '72,204,204'],
  ['绿松石色', '#30d5c8', '48,200,200'],
  ['土耳其蓝', '#33e6cc', '51,204,204'],
  ['亮海绿', '#20b2aa', '32,170,170'],
  ['青蓝', '#0dbf8c', '13,140,140'],
  ['品緑', '#07817a', '7,122,122'],
  ['湖緑', '#015f5a', '1,90,90'],
  ['青色', '#00ffff', '0,255,255'],
  ['深天蓝', '#00bfff', '0,255,255'],
  ['暗绿松石色', '#00ced1', '0,209,209'],
  ['暗青', '#008b8b', '0,139,139'],
  ['孔雀蓝', '#00808c', '0,140,140'],
  ['凫绿', '#008080', '0,128,128'],
  ['浓蓝', '#006374', '0,116,116'],
  ['沙緑', '#003129', '0,41,41'],
  ['银蓝', '#e1e3e6', '225,230,230'],
  ['爱丽丝蓝', '#f0f8ff', '240,255,255'],
  ['鉄青', '#292c2e', '41,46,46'],
  ['亮钢蓝', '#b0c4de', '176,222,222'],
  ['亮岩灰', '#778899', '119,153,153'],
  ['岩灰', '#708090', '112,144,144'],
  ['花青', '#576d93', '87,147,147'],
  ['鹊灰', '#283144', '40,68,68'],
  ['钴蓝', '#6b9bb8', '107,184,184'],
  ['浅蓝', '#89cff0', '137,240,240'],
  ['绀青', '#3f4470', '63,112,112'],
  ['浅天蓝', '#87cefa', '135,250,250'],
  ['竹月色', '#6b9ac9', '107,201,201'],
  ['栢坊灰蓝', '#41627c', '65,124,124'],
  ['灰丁宁蓝', '#5e86c1', '94,193,193'],
  ['韦奇伍德瓷蓝', '#5686bf', '86,191,191'],
  ['矢车菊蓝', '#6495ed', '100,237,237'],
  ['钢青色', '#4682b4', '70,180,180'],
  ['浅海昌蓝', '#2f4988', '47,136,136'],
  ['鼠尾草蓝', '#4d80e6', '77,230,230'],
  ['労动布色', '#1c2859', '28,89,89'],
  ['品蓝', '#4169e1', '65,225,225'],
  ['暗矿蓝', '#24367d', '36,125,125'],
  ['深毛月色', '#284f95', '40,149,149'],
  ['沙青', '#205580', '32,128,128'],
  ['琉璃蓝', '#183a65', '24,101,101'],
  ['浅土蓝', '#122c4b', '18,75,75'],
  ['绒蓝', '#1f4f89', '31,137,137'],
  ['北京毛蓝', '#1f4f89', '31,137,137'],
  ['深竹月', '#2e62cd', '46,205,205'],
  ['毛月色', '#2c80c5', '44,197,197'],
  ['蔚蓝', '#2a52be', '42,190,190'],
  ['深蓝', '#0e1a49', '14,73,73'],
  ['蔵蓝', '#111f6a', '17,106,106'],
  ['道奇蓝', '#1e90ff', '30,255,255'],
  ['海蓝', '#0b2d64', '11,100,100'],
  ['蓝宝石色', '#082567', '8,103,103'],
  ['深毛蓝', '#031025', '3,37,37'],
  ['天青石蓝', '#0d33ff', '13,255,255'],
  ['郡青', '#043ab9', '4,185,185'],
  ['湛蓝', '#007fff', '0,255,255'],
  ['极浓海蓝', '#0033ff', '0,255,255'],
  ['国际奇连蓝', '#002fa7', '0,167,167'],
  ['矿蓝', '#004d99', '0,153,153'],
  ['暗婴儿粉蓝', '#003399', '0,153,153'],
  ['水手蓝', '#00477d', '0,125,125'],
  ['普鲁士蓝', '#003153', '0,83,83'],
  ['雪灰', '#ededef', '237,239,239'],
  ['幽灵白', '#f8f8ff', '248,255,255'],
  ['薰衣草紫', '#e6e6fa', '230,250,250'],
  ['雪青灰', '#bfbed3', '191,211,211'],
  ['闪蝶紫', '#b4a4ca', '180,202,202'],
  ['矿紫', '#b8a1cf', '184,207,207'],
  ['青金色', '#9fa0d7', '159,215,215'],
  ['罗蓝灰', '#3c374a', '60,74,74'],
  ['浅灰紫红', '#8674a1', '134,161,161'],
  ['紫水晶', '#d9b3ff', '217,255,255'],
  ['紫丁香色', '#b399ff', '179,255,255'],
  ['浅石英紫', '#cc99ff', '204,255,255'],
  ['蔵墨蓝', '#27243c', '39,60,60'],
  ['雪蓝', '#777bce', '119,206,206'],
  ['中紫红', '#9370db', '147,219,219'],
  ['靛蓝', '#201f3d', '32,61,61'],
  ['木槿紫', '#bf80ff', '191,255,255'],
  ['钛鉄', '#1a152b', '26,43,43'],
  ['中岩蓝', '#7b68ee', '123,238,238'],
  ['岩蓝', '#6a5acd', '106,205,205'],
  ['暗岩蓝', '#483d8b', '72,139,139'],
  ['紫藤色', '#5c50e6', '92,230,230'],
  ['紫水晶色', '#6633cc', '102,204,204'],
  ['午夜蓝', '#191970', '25,112,112'],
  ['宝石蓝', '#1e25bb', '30,187,187'],
  ['鲜蓝', '#1515f9', '21,249,249'],
  ['蓝色', '#0000ff', '0,255,255'],
  ['中蓝', '#0000cd', '0,205,205'],
  ['缬草紫', '#5000b8', '80,184,184'],
  ['暗蓝', '#00008b', '0,139,139'],
  ['藏青', '#000080', '0,128,128'],
  ['浅藤紫', '#ece4f3', '236,243,243'],
  ['淡紫丁香色', '#e6cfe6', '230,230,230'],
  ['蓟紫', '#d8bfd8', '216,216,216'],
  ['莲灰', '#d1bfdd', '209,221,221'],
  ['紫藤灰', '#b5a4c4', '181,196,196'],
  ['铁线莲紫', '#cca3cc', '204,204,204'],
  ['梅红色', '#dda0dd', '221,221,221'],
  ['紫薇花', '#ff99ff', '255,255,255'],
  ['凤仙紫', '#9565b1', '149,177,177'],
  ['丁香紫', '#503a65', '80,101,101'],
  ['亮紫', '#ee82ee', '238,238,238'],
  ['兰紫', '#da70d6', '218,214,214'],
  ['洋葱紫', '#8e488e', '142,142,142'],
  ['优品紫红', '#e680ff', '230,255,255'],
  ['浅凤仙紫', '#e666ff', '230,255,255'],
  ['中兰紫', '#ba55d3', '186,211,211'],
  ['紫罗蓝', '#732e7e', '115,126,126'],
  ['锦葵紫', '#d94dff', '217,255,255'],
  ['暗兰紫', '#9932cc', '153,204,204'],
  ['蓝紫', '#8a2be2', '138,226,226'],
  ['紫罗兰色', '#8b00ff', '139,255,255'],
  ['洋红', '#ff00ff', '255,255,255'],
  ['暗紫', '#9400d3', '148,211,211'],
  ['三色堇紫', '#7400a1', '116,161,161'],
  ['青莲紫', '#730099', '115,153,153'],
  ['暗洋红', '#8b008b', '139,139,139'],
  ['靛色', '#4b0080', '75,128,128'],
  ['紫色', '#800080', '128,128,128'],
  ['竜胆紫', '#1a0033', '26,51,51'],
  ['白色', '#ffffff', '255,255,255'],
  ['立徳粉', '#ffffff', '255,255,255'],
  ['白烟色', '#f5f5f5', '245,245,245'],
  ['庚斯博罗灰', '#dcdcdc', '220,220,220'],
  ['亮灰色', '#d3d3d3', '211,211,211'],
  ['银箔', '#d4d4d4', '212,212,212'],
  ['银色', '#c0c0c0', '192,192,192'],
  ['灰色', '#808080', '128,128,128'],
  ['银鼠灰', '#797979', '121,121,121'],
  ['昏灰', '#696969', '105,105,105'],
  ['青灰色', '#484848', '72,72,72'],
  ['百草霜', '#1c1c1c', '28,28,28'],
  ['黑色', '#000000', '0,0,0'],
  ['妃色', '#ED5736', '237,87,54'],
  ['桃红', '#F47983', '244,121,131'],
  ['海棠红', '#DB5A6B', '219,90,107'],
  ['石榴红', '#F20C00', '242,12,0'],
  ['樱桃色', '#C93756', '201,55,86'],
  ['大红', '#FF2121', '255,33,33'],
  ['胭脂', '#9D2933', '157,41,51'],
  ['丹', '#FF4E20', '255,78,32'],
  ['彤', '#F35336', '243,83,54'],
  ['茜色', '#CB3A56', '203,58,86'],
  ['火红', '#FF2D51', '255,45,81'],
  ['赫赤', '#C91F37', '201,31,55'],
  ['嫣红', '#EF7A82', '239,122,130'],
  ['炎', '#FF3300', '255,51,0'],
  ['赤', '#C3272B', '195,39,43'],
  ['绾', '#A98175', '169,129,117'],
  ['檀', '#B36D61', '179,109,97'],
  ['殷红', '#BE002F', '190,0,47'],
  ['酡红', '#DC3023', '220,48,35'],
  ['酡颜', '#F9906F', '249,144,111'],
  ['鸭黄', '#FAFF72', '250,255,114'],
  ['樱草色', '#EAFF56', '234,255,86'],
  ['杏红', '#FF8C31', '255,140,​​49'],
  ['橘黄', '#FF8936', '255,137,54'],
  ['橙黄', '#FFA400', '255,164,0'],
  ['橘红', '#FF7500', '255,117,0'],
  ['缃色', '#F0C239', '240,194,57'],
  ['茶色', '#B35C44', '179,92,68'],
  ['昏黄', '#C89B40', '200,155,64'],
  ['棕色', '#B25D25', '178,93,37'],
  ['棕绿', '#827100', '130,113,0'],
  ['棕黑', '#7C4B00', '124,75,0'],
  ['棕黄', '#AE7000', '174,112,0'],
  ['琥珀', '#CA6924', '202,105,36'],
  ['枯黄', '#D3B17D', '211,177,125'],
  ['黄栌', '#E29C45', '226,156,69'],
  ['秋色', '#896C39', '137,108,57'],
  ['秋香色', '#D9B611', '217,182,17'],
  ['柳黄', '#C9DD22', '201,221,34'],
  ['柳绿', '#AFDD22', '175,221,34'],
  ['竹青', '#789262', '120,146,98'],
  ['葱黄', '#A3D900', '163,217,0'],
  ['葱绿', '#9ED900', '158,217,0'],
  ['葱青', '#0EB83A', '14,184,58'],
  ['青葱', '#0AA344', '10,163,68'],
  ['油绿', '#00BC12', '0,188,18'],
  ['绿沉', '#0C8918', '12,137,24'],
  ['碧色', '#1BD1A5', '27,209,165'],
  ['青碧', '#48C0A3', '72,192,163'],
  ['翡翠色', '#3DE1AD', '61,225,173'],
  ['青翠', '#00E079', '0,224,121'],
  ['青白', '#C0EBD7', '192,235,215'],
  ['鸭卵青', '#E0EEE8', '224,238,232'],
  ['蟹壳青', '#BBCDC5', '187,205,197'],
  ['鸦青', '#424C50', '66,76,80'],
  ['豆绿', '#9ED048', '158,208,72'],
  ['豆青', '#96CE54', '150,206,84'],
  ['石青', '#7BCFA6', '123,207,166'],
  ['玉色', '#7BCFA6', '46,223,163'],
  ['缥', '#7FECAD', '127,236,173'],
  ['艾绿', '#A4E2C6', '164,226,198'],
  ['松柏绿', '#21A675', '33,166,117'],
  ['松花绿', '#057748', '5,119,72'],
  ['松花色', '#BCE672', '5,119,72'],
  ['蓝', '#44CEF6', '68,206,246'],
  ['靛青', '#177CB0', '23,124,176'],
  ['碧蓝', '#3EEDE7', '62,237,231'],
  ['宝蓝', '#4B5CC4', '75,92,196'],
  ['藏蓝', '#3B2E7E', '59,46,126'],
  ['黛', '#4A4266', '74,66,102'],
  ['黛绿', '#426666', '66,102,102'],
  ['黛紫', '#574266', '87,66,102'],
  ['紫酱', '#815463', '129,84,99'],
  ['酱紫', '#815476', '129,84,118'],
  ['紫檀', '#4C221B', '76,34,27'],
  ['紫棠', '#56004F', '86,0,79'],
  ['青莲', '#801DAE', '128,29,174'],
  ['群青', '#4C8DAE', '76,141,174'],
  ['雪青', '#B0A4E3', '176,164,227'],
  ['丁香色', '#CCA4E3', '204,164,227'],
  ['藕色', '#EDD1D8', '237,209,216'],
  ['藕荷色', '#E4C6D0', '228,198,208'],
  ['苍青', '#A29B7C', '162,155,124'],
  ['苍黑', '#7397AB', '115,151,171'],
  ['苍白', '#D1D9E0', '209,217,224'],
  ['水红', '#F3D3E7', '243,211,231'],
  ['水绿', '#D4F2E7', '212,242,231'],
  ['淡青', '#D3E0F3', '211,224,243'],
  ['湖蓝', '#30DFF3', '48,2​​23,243'],
  ['湖绿', '#25F8CB', '37,248,203'],
  ['精白', '#FFFFFF', '255,255,255'],
  ['像牙白', '#FFFBF0', '255,251,240'],
  ['雪白', '#F0FCFF', '240,252,255'],
  ['月白', '#D6ECF0', '214,236,240'],
  ['缟', '#F2ECDE', '242,236,222'],
  ['素', '#E0F0E9', '224,240,233'],
  ['荼白', '#F3F9F1', '243,249,241'],
  ['霜色', '#E9F1F6', '233,241,246'],
  ['花白', '#C2CCD0', '194,204,208'],
  ['鱼肚白', '#FCEFE8', '252,239,232'],
  ['莹白', '#E3F9FD', '227,239,253'],
  ['牙色', '#EEDEB0', '238,222,176'],
  ['铅白', '#F0F0F4', '240,240,244'],
  ['玄色', '#622A1D', '98,42,29'],
  ['玄青', '#3D3B4F', '61,59,79'],
  ['乌色', '#725E82', '114,94,130'],
  ['乌黑', '#392F41', '57,47,65'],
  ['漆黑', '#161823', '22,24,35'],
  ['墨色', '#50616D', '80,97,109'],
  ['墨灰', '#758A99', '117,138,153'],
  ['缁色', '#493131', '73,49,49'],
  ['煤黑', '#312520', '49,37,32'],
  ['黧', '#5D513C', '93,81,60'],
  ['黎', '#75664D', '117,102,77'],
  ['黝', '#6B6882', '107,104,130'],
  ['黝黑', '#665757', '102,87,87'],
  ['黯', '#41555D', '65,85,93'],
  ['赤金', '#F2BE45', '242,190,69'],
  ['银白', '#E9E7EF', '233,231,239'],
  ['铜绿', '#549688', '84,150,136'],
  ['乌金', '#A78E44', '167,142,68'],
  ['老银', '#BACAC6', '186,202,198'],
  ['猩红色', '#FF2400', '255,36,0'],
  ['朱红色', '#FF4D00', '255,77,0'],
  ['柠檬绿色', '#CCFF00', '204,255,0'],
  ['黄绿色', '#66FF00', '102,255,0'],
  ['蔚蓝色', '#007FFF', '0,127,255'],
  ['蓝绿色', '#7FFFD4', '127,255,212'],
  ['浅蓝色', '#E0FFFF', '137,207,240'],
  ['绿松色', '#30D5C8', '48,213,200'],
  ['粉末蓝', '#003399', '0,51,153'],
  ['皇室蓝', '#4169E1', '65,105,225'],
  ['天青蓝', '#2A52BE', '42,82,190'],
  ['钴蓝色', '#0047AB', '0,71,171'],
  ['海军蓝', '#000080', '0,0,128'],
  ['白牛仔布色', '#5E86C1', '94,134,193'],
  ['青玉色', '#082567', '8,37,103'],
  ['深茜红', '#E32636', '227,38,54'],
  ['洋红色', '#FF00FF', '255,0,255'],
  ['橙黄色', '#FFCC00', '255,204,0'],
  ['春绿色', '#00FF80', '0,255,128'],
  ['鸭绿色', '#008080', '0,128,128'],
  ['鲑肉色', '#FF8C69', '255,140,105'],
  ['米色', '#F5F5DC', '245,245,210'],
  ['薰衣草色', '#E6E6FA', '230,230,250'],
  ['兰花色', '#DA70D6', '218,112,214'],
  ['粉红色', '#FFC0CB', '255,192,203'],
  ['燃橙色', '#CC5500', '204,85,0'],
  ['暗灰色', '#404040', '64,64,64'],
];

export const colors: string[] = [
  '赤',
  '朱',
  '丹',
  '绯',
  '彤',
  '绛',
  '茜',
  '纁',
  '赭',
  '栗',
  '褐',
  '驼',
  '赭',
  '橘',
  '曙',
  '翠',
  '碧',
  '金',
  '米',
  '缃',
  '靛',
  '紫',
  '藕',
  '桃',
  '青',
  '玄',
  '皂',
  '乌',
  '墨',
  '黛',
  '黝',
  '素',
  '杏',
  '缟',
  '鹤',
  '皓',
  '苍',
  '华',
  '银',
  ...COLOR_WITH_RGB.map(item => item[0]),
];
